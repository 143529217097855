









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { fetchProducerhasEliteSubscription } from '@elitepage/api/elitepage'
import { ModalType } from '~/store/modal'
import { UserStore } from '@elitepage/store/user'
import { injectFacebookPixel } from '~/utils/injectFacebookPixel'

@Component
export default class ProducerContainer extends Vue {
	@Prop({ type: String }) producerSlug!: IProducer['slug']

	@ElitepageStore.State('elitepage') elitepage: IElitepage
	@ElitepageStore.Action('fetchSingleProducer') fetchSingleProducer: (producerSlug: IProducer['slug']) => void
	@ElitepageStore.Action('loadElitepage') loadElitepage: (producerSlug: IProducer['slug']) => void
	@UserStore.State('authenticatedUser') authenticatedUser: IUser

	get blurBackground() {
		if (this.$store.state.modal.activeModal?.modal === ModalType.LicenseOptionSelection) return 'h-blur'
	}

	@Watch('elitepage')
	elitepageChanged(newValue: IElitepage) {
		if (newValue.seo_title) document.title = newValue.seo_title
		if (newValue.seo_description) document.querySelector('meta[name="description"]').setAttribute('content', newValue.seo_description)
		if (newValue.seo_keywords) document.querySelector('meta[name="keywords"]').setAttribute('content', newValue.seo_keywords)
		if (newValue.producer_id) {
			// track page view in our backend
			Vue.$axios.post(`/api/track_page_visit/`, {
				producer_id: newValue.producer_id,
				from_elite_page: true
			})
		}

		// track with facebook pixel
		if (newValue.facebook_pixel_id) {
			injectFacebookPixel(newValue.facebook_pixel_id)
		}
	}

	async checkSubscription() {
		const hasPremiumSub = await fetchProducerhasEliteSubscription(this.producerSlug)
		if (!hasPremiumSub) {
			await this.$router.replace({ name: 'NotFound' })
		}
	}

	async mounted() {
		this.checkSubscription()
		await this.fetchSingleProducer(this.producerSlug)
		await this.loadElitepage(this.producerSlug)
	}
}
